import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'apps' }
    },
    {
      path: '/apps',
      name: 'apps',
      component: () => import('@/views/Apps.vue'),
      beforeEnter: (to, from, next) => { 
        restoreSession()
          .then(() => {
            requiresAuth(to, from, next)
          })
        }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      beforeEnter: (to, from, next) => { 
        restoreSession()
          .then(() => {
            requiresAuth(to, from, next)
          })
        }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      },
      beforeEnter: (to, from, next) => { 
        restoreSession()
          .then(() => {
            checkLoginIsNeeded(to, from, next)
          })
        }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

function requiresAuth(to, from, next) {
  const isAuthenticated = store.getters['auth/isUserAuthenticated']
  if (!isAuthenticated) {
    next({name: 'login'})
  } else {
    next()
  }
}

function checkLoginIsNeeded(to, from, next) {
  const isAuthenticated = store.getters['auth/isUserAuthenticated']
  if (isAuthenticated) {
    next({ name: 'apps' })
  } else {
    next()
  }
}

async function restoreSession() {
  const isAuthenticated = store.getters['auth/isUserAuthenticated']
  if (!isAuthenticated) {
    try {
      console.log('Try to Get User from storage')
      await store.dispatch('auth/getCurrentUser')
      console.log('User retrieved')
    } catch (error) {
      console.log(error.message)
    }
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
