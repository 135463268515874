import axios from '@axios'
import store from '@/store'

const API_URL = process.env.VUE_APP_EAMS_ENDPOINT
const PPAS_PROD_URL = process.env.VUE_APP_PPAS_ENDPOINT
const PPAS_TEST_URL = process.env.VUE_APP_PPAS_TEST_ENDPOINT
const PROXY_PPAS_PROD = API_URL + '/ppas'
const PROXY_PPAS_TEST = API_URL + '/ppas-test'


// Set local storage with ttl
const setWithExpiry = (key, value, ttl) => {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + (ttl * 1000) //seconds
  }
  localStorage.setItem(key, JSON.stringify(item))
}

// Get local Storage and checks ttl
const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return false
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return false
  }

  console.log('Token is valid!')
  return item.value
}

// Get the token for the EAMS service
const getEAMSToken = () => {
  const userData = store.getters['auth/user']
  return userData.tokens.IdToken
}

// Get PPAS Token from localstorage
const getPPASToken = (testing) => {
  const suffix = testing ? 'test' : 'prod'
  const key = 'ppas-' + suffix
  return getWithExpiry(key)
}

// Set PPAS Token from localstorage
const setPPASToken = (testing, token) => {
  const suffix = testing ? 'test' : 'prod'
  const key = 'ppas-' + suffix
  const ttl = 1800 //seconds - 30 minutes
  setWithExpiry(key, token, ttl)
}

// Build PPAS Credentials Object
const getPPASCredentials = (testing = false) => {
  const userData = store.getters['auth/user']
  const userAttributes = userData.attributes

  return {
    grant_type: 'client_credentials',
    client_id: testing?
      userAttributes['custom:ec_client_id_t']:
      userAttributes['custom:ec_client_id'],
    client_secret: testing?
      userAttributes['custom:ec_client_secret_t']:
      userAttributes['custom:ec_client_secret']
  }
}

export default {
  namespaced: true,
  state: {
    apps: [],
    currentApp: {},
    totalApps: 0,
    isTesting: false,
    ppasInstanceToken: false
  },
  getters: {
    apps: state => {
      return state.apps
    },
    totalApps: state => {
      return state.totalApps
    }
  },
  mutations: {
    SET_CURRENT_APP(state, payload) {
      state.currentApp = payload
    },
    SET_APPS(state, payload) {
      state.apps = payload
      state.totalApps = state.apps.length
    },
    ADD_APP(state, payload) {
      state.apps.push(payload)
      state.totalApps = state.apps.length
    },
    UPDATE_APP(state, payload) {
      let index = state.apps.findIndex(x => x.rsiteID === payload.rsiteID)
      state.apps.splice(index, 1, payload)
    },
    REMOVE_APP(state, payload) {
      let index = state.apps.findIndex(x => x.rsiteID === payload)
      state.apps = [...state.apps.slice(0, index), ...state.apps.slice(index + 1)]
      state.totalApps = state.apps.length
    },
    SET_IS_TESTING(state, payload) {
      state.isTesting = payload
    },
    SET_PPAS_INSTANCE_TOKEN(state, payload) {
      state.ppasInstanceToken = payload
    },
    SET_CURRENT_APP_IDS(state, payload) {
      state.currentApp.rsiteID = payload
      if (!state.currentApp.legacy) {
        state.currentApp.siteID = payload
      }
    }
  },
  actions: {
    // Login to PPAS and get the token valid for 30m
    signInPPAS({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const isTesting = state.isTesting
        let _token = getPPASToken(isTesting)
        // If token in localstorage is still valid
        if (_token) {
          commit('SET_PPAS_INSTANCE_TOKEN', _token)
          return resolve()
        }
        const credentials = getPPASCredentials(isTesting)
        const PPAS_URL = isTesting ? PROXY_PPAS_TEST: PROXY_PPAS_PROD

        try {
          const response = await axios.post(`${PPAS_URL}/auth`, credentials)
          console.log(response.data.access_token)
          _token = response.data.access_token
          console.log(_token)
          setPPASToken(isTesting, _token)
          commit('SET_PPAS_INSTANCE_TOKEN', _token)
          return resolve()
        } catch (error) {
          console.log(`Error: getting token (PPAS) failed: ${error}`)
          commit('SET_PPAS_INSTANCE_TOKEN', false)
          return reject()
        }
      })
    },

    // Add PPAS app
    addPPASApp({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const _token = state.ppasInstanceToken
        if (!_token) {
          console.log(`Error getting token (PPAS): ${error}`)
          return reject()
        }

        const app = state.currentApp
        const isTesting = state.isTesting
        const PPAS_URL = isTesting ? PROXY_PPAS_TEST: PROXY_PPAS_PROD

        try {
          const headers = {
            'Content-Type': 'application/vnd.api+json',
            Authorization: 'Bearer ' + _token 
          }

          const data = {
            data: {
              type: 'ppms/app',
              attributes: {
                appType: 'web',
                timezone: 'Europe/Brussels',
                name: app.title,
                urls: app.url,
                currency: 'EUR'
              }
            }
          }

          const response = await axios.post(PPAS_URL, data, { headers })
          if (response.data.errors) {
            console.log(`Error: Adding the APP (PPAS): ${response.data.errors}`)
            return reject()
          }

          if (!response.data.data.id) {
            console.log(`Error: Missing APP ID from ${PPAS_URL}`)
            return reject()
          }

          console.log(`NEW APP added to ${PPAS_URL}, id: ${response.data.data.id}`)
          commit('SET_CURRENT_APP_IDS', response.data.data.id)
          return resolve()
        } catch (error) {
          console.log(`Error: Creating APP (PPAS) failed: ${error}`)
          return reject()
        }
      })
    },

    // Update APP in PPAS
    updatePPASApp({ state }) {
      return new Promise(async (resolve, reject) => {
        const _token = state.ppasInstanceToken
        if (!_token) {
          console.log(`Error: No token (PPAS)`)
          return reject()
        }

        const app = state.currentApp
        const isTesting = state.isTesting
        const PPAS_URL = isTesting ? PROXY_PPAS_TEST: PROXY_PPAS_PROD

        const headers = {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + _token
        }

        const data = {
          data: {
            type: 'ppms/app',
            id: app.rsiteID,
            attributes: {
              appType: 'web',
              timezone: 'Europe/Brussels',
              name: app.title,
              urls: app.url,
              currency: 'EUR'
            }
          }
        }

        try {
          await axios.patch(`${PPAS_URL}/${app.rsiteID}`, data, { headers })
          console.log(`APP Updated: ${app.rsiteID} from ${PPAS_URL}`)
          return resolve()

        } catch (error) {
          console.log(`Error: Updating the APP (PPAS) failed: ${error}`)
          return reject()
        }
      })
    },

    // Remove PPAS App
    removePPASApp({ state }) {
      return new Promise(async (resolve, reject) => {
        const _token = state.ppasInstanceToken
        if (!_token) {
          console.log(`Error: No token (PPAS)`)
          return reject()
        }
        const app = state.currentApp
        const isTesting = state.isTesting
        const PPAS_URL = isTesting ? PROXY_PPAS_TEST: PROXY_PPAS_PROD

        const headers = {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + _token
        }

        try {
          await axios.delete(`${PPAS_URL}/${app.rsiteID}`, { headers })
          console.log(`APP Removed: ${app.rsiteID} from ${PPAS_URL}`)
          return resolve()

        } catch (error) {
          console.log(`Error: removing APP (PPAS) failed: ${error}`)
          return reject()
        }

      })
    },

    // Get all the Apps in EAMS
    fetchApps({ commit }) {
      return new Promise(async (resolve, reject) => {
        let token = getEAMSToken()
        let headers = { Authorization: 'Bearer ' + token }
        try {
          const response = await axios.get(`${API_URL}/admin`, { headers })
          commit('SET_APPS', response.data.data)
          return resolve(true)

        } catch (error) {
          console.log(`Error: Getting Apps (EAMS) failed: ${error}`)
          return reject()
        }
      })
    },

    // Add App in EAMS
    addApp({ state, commit, dispatch }, app) {
      return new Promise(async (resolve, reject) => {
        commit('SET_CURRENT_APP', app)
        commit('SET_IS_TESTING', app.trackerURL === PPAS_TEST_URL)

        // Get PPAS token
        try {
          await dispatch('signInPPAS')
        } catch (error) {
          return reject()
        }

        // Add APP PPAS
        try {
          await dispatch('addPPASApp')
          // get App IDs (rsiteID and siteID if legacy = true)
          app = state.currentApp
        } catch (error) {
          console.log(`Error: Creating App (PPAS) failed: ${error}`)
          return reject()
        }

        // Add App EAMS
        try {
          let token = getEAMSToken()
          let headers = { Authorization: 'Bearer ' + token }
          await axios.post(`${API_URL}/admin`, app, { headers })
          commit('ADD_APP', app)
          return resolve()
        } catch (error) {
          console.log(`Error: Creating App (EAMS) failed: ${error}`)
          return reject()
        }
      })
    },

    // Update App
    updateApp({ commit, dispatch }, app) {
      return new Promise(async (resolve, reject) => {
        const updatePPAS = app.ppasChanges
        const trackerURLChanged = app.trackerURLChanges
        delete app.ppasChanges
        delete app.trackerURLChanges

        // Update EAMS
        try {
          let token = getEAMSToken()
          let headers = { Authorization: 'Bearer ' + token }
          await axios.post(`${API_URL}/admin?siteID=${app.rsiteID}`, app, { headers })
          commit('UPDATE_APP', app)
        } catch (error) {
          console.log(`Error: Updating App (EAMS) failed: ${error}`)
          return reject()
        }

        // If no changes to PPAS fields
        if (!updatePPAS && !trackerURLChanged) {
          return resolve()
        }

        commit('SET_CURRENT_APP', app)
        const isTesting = app.trackerURL === PPAS_TEST_URL
        commit('SET_IS_TESTING', isTesting)

        // Get PPAS token
        try {
          await dispatch('signInPPAS')
        } catch (error) {
          return reject()
        }

        // if the Instance was changed
        if (trackerURLChanged) {
          try {
            // remove App from the other instance 
            commit('SET_IS_TESTING', !isTesting) 
            await dispatch('removePPASApp')
            commit('SET_IS_TESTING', isTesting)
            // add new to the correct instance
            await dispatch('addPPASApp')
            return resolve()
          } catch (error) {
            console.log(`Error: Updating App Tracker URL (EAMS) failed: ${error}`)
            return reject()
          }
        }

        // Update APP PPAS
        try {
          await dispatch('updatePPASApp')
          return resolve()
        } catch (error) {
          console.log(`Error: Updating App (PPAS) failed: ${error}`)
          return reject()
        }
      })
    },
    
    // Remove App
    removeApp({ commit, dispatch }, app) {
      return new Promise(async (resolve, reject) => {
        commit('SET_CURRENT_APP', app)
        commit('SET_IS_TESTING', app.trackerURL === PPAS_TEST_URL)

        // Get PPAS token
        try {
          await dispatch('signInPPAS')
        } catch (error) {
          return reject()
        }

        // Remove APP PPAS and EAMS
        try {
          await dispatch('removePPASApp')
        } catch (error) {
          console.log(`Error: Removing App (PPAS) failed: ${error}`)
          return reject()
        }

        // Remove APP EAMS
        try {
          let token = getEAMSToken()
          let headers = { Authorization: 'Bearer ' + token }
          axios.delete(`${API_URL}/admin?siteID=${app.rsiteID}`, { headers })
          commit('REMOVE_APP', app.rsiteID)
          commit('SET_CURRENT_APP', {})
          return resolve()
        } catch (error) {
          console.log(`Removing App (EAMS) failed: ${error}`)
          return reject()
        }
      })
    }
    
  }
}